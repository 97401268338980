// dependencies
import React, { useMemo, Fragment, useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, makeStyles, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import Helmet from 'react-helmet'
// hooks
import useImageLoaded from '@hooks/useImageLoaded'
// components
import Link from './Link'
import Placeholder from './Placeholder'

// The rtg image api uses f=webp or fm=webp, Safari only support fm=webp
const getSrcSets = imageURL =>
  imageURL
    ? [
        {
          srcSet: `${imageURL}&fm=webp`,
          type: 'image/webp',
        },
        {
          srcSet: imageURL,
        },
      ]
    : null

const getPreloadImageData = ({ srcSet, media, type = null }) => [
  {
    ...(media ? { media } : {}),
    ...(type
      ? {
          href: `${srcSet}&fm=webp`,
          type,
        }
      : {
          href: srcSet,
        }),
    rel: 'preload',
    as: 'image',
  },
]

const absoluteContent = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
}

const useStyles = makeStyles(() => ({
  imageWrapper: ({ ratio, height }) => ({
    display: 'flex',
    width: '100%',
    height: height ? `${height}px` : '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    ...(height ? {} : { paddingBottom: `${ratio}%` }),
  }),
  image: ({ objectFit, isLoaded }) => ({
    width: '100%',
    height: '100%',
    objectFit: objectFit || 'cover',
    display: isLoaded ? 'initial' : 'none',
    ...absoluteContent,
  }),
  imageAuto: ({ objectFit, isLoaded }) => ({
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    objectFit: objectFit || 'cover',
    display: isLoaded ? 'initial' : 'none',
    ...absoluteContent,
  }),
  placeholder: {
    ...absoluteContent,
  },
}))

const calculateRatio = (height, width) => {
  const ratio = (height / width) * 100
  return Math.round((ratio + Number.EPSILON) * 100) / 100
}

const StrapiImage = ({
  data,
  link = null,
  auto = false,
  className = '',
  wrapperClassName = '',
  trackingData = null,
  height,
}) => {
  const isMobile = useMediaQuery('(max-width: 639px)')

  const fallbackImage = data?.mobile && isMobile ? data?.mobile : data?.desktop || {}
  const wrapperProps = link ? { component: Link, data: link, trackingData } : {}

  const mobileSources = useMemo(() => getSrcSets(data?.mobile?.url), [data])
  const desktopSources = useMemo(() => getSrcSets(data?.desktop?.url), [data])

  const { isLoaded, onLoad, imageRef } = useImageLoaded()

  const classes = useStyles({
    objectFit: data?.imageSizing,
    isLoaded,
    height,
    // Setting up aspect-ratio friendly boxes in order to reduce CLS
    // ? https://css-tricks.com/aspect-ratio-boxes/
    ratio: calculateRatio(fallbackImage.height || 550, fallbackImage.width || 800),
  })

  return (
    <Box {...wrapperProps} className={classNames(classes.imageWrapper, wrapperClassName)}>
      {!isLoaded && <Placeholder height="100%" width="100%" className={classes.placeholder} />}
      <picture>
        {mobileSources &&
          mobileSources.map(source => {
            const sourceProps = { ...source, media: '(max-width: 639px)' }
            return (
              <Fragment key={`${source.srcSet}-mobile`}>
                <source {...sourceProps} />
                <Helmet link={getPreloadImageData(sourceProps)} />
              </Fragment>
            )
          })}
        {desktopSources &&
          desktopSources.map(source => {
            const media = mobileSources ? '(min-width: 640px)' : null
            const sourceProps = { ...source, ...(media ? { media } : {}) }
            return (
              <Fragment key={`${source.srcSet}-desktop`}>
                <source {...sourceProps} />
                <Helmet link={getPreloadImageData(sourceProps)} />
              </Fragment>
            )
          })}
        {/* Fallback */}
        <img
          ref={imageRef}
          onLoad={onLoad}
          className={classNames(auto ? classes.imageAuto : classes.image, className)}
          src={fallbackImage?.url}
          alt={fallbackImage?.alternativeText || data?.alt || 'Rooms To Go Banner'}
        />
      </picture>
    </Box>
  )
}

StrapiImage.propTypes = {
  data: PropTypes.shape({
    desktop: PropTypes.object,
    mobile: PropTypes.object,
    imageSizing: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
  link: PropTypes.object,
  trackingData: PropTypes.object,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  auto: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  height: PropTypes.number,
}

export default StrapiImage

export const StrapiImageFragment = graphql`
  fragment StrapiImageFragment on StrapiImage {
    url
    alternativeText
    id
    mime
    height
    width
  }
`
