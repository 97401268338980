// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
// helpers
import { mapSpacing, toGridBreakpoint } from '@helpers/strapi'
// context
import { useBannerContext } from '@context/bannerContext'

// components
import BaseBanner from './BaseBanner'
import Button from './BannerButton'
import Markdown from '../Markdown'
import Image from '../Image'

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    '& > div': {
      height: 'auto',
      flexBasis: 'auto',
    },
  },
}))

const justify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const align = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end',
}

const StrapiContentBanner = ({ Banner, Section, ...gridProps }) => {
  const classes = useStyles()
  const { isHero } = useBannerContext()
  const content = Banner?.Content || []

  const hasImage = Section?.BackgroundImage || Section?.MobileBackgroundImage
  const direction = Banner?.Orientation || 'row'
  const props = {
    ...gridProps,
    className: classNames(gridProps?.className || '', classes.contentWrapper),
    direction,
    justify: direction === 'row' ? justify[Banner?.Alignment] || null : align[Banner?.VerticalAlignment] || null,
    alignItems: direction === 'row' ? align[Banner?.VerticalAlignment] || null : justify[Banner?.Alignment] || null,
  }

  const ContentComponents = content.map(bannerContent => {
    const MarkdownData = bannerContent?.BannerText || null
    const ButtonData = bannerContent?.BannerButton || null
    const BannerImage = bannerContent?.BannerImage || null

    const View = {
      BannerBannerText: MarkdownData && (
        <Markdown
          data={{
            Markdown: { Markdown: MarkdownData?.BannerText, id: MarkdownData?.id },
            PaddingTop: MarkdownData?.PaddingTop,
            PaddingBottom: MarkdownData?.PaddingBottom,
            PaddingLeft: MarkdownData?.PaddingLeft,
            PaddingRight: MarkdownData?.PaddingRight,
          }}
          key={MarkdownData.id}
          Alignment={Banner?.Alignment}
        />
      ),
      BannerBannerButton: ButtonData && <Button data={ButtonData} key={ButtonData.id} />,
      BannerBannerImage: BannerImage && (
        <Grid
          item
          md={BannerImage?.ColumnSize || 12}
          style={{
            width: '100%',
          }}
        >
          <Image
            loading={isHero ? 'eager' : 'lazy'}
            auto
            data={{ mobile: BannerImage?.MobileImage, desktop: BannerImage?.Image }}
            key={BannerImage.id}
          />
        </Grid>
      ),
    }

    return View[bannerContent.__typename] || null
  })

  return hasImage ? (
    <BaseBanner
      data={{ Banner: Section }}
      key={`${Banner.id}}-content`}
      md={toGridBreakpoint(Banner?.ColumnSize)}
      spacing={mapSpacing(Banner?.ContentSpacing)}
      {...props}
    >
      {ContentComponents}
    </BaseBanner>
  ) : (
    <Grid
      key={`${Banner.id}}-content`}
      item
      md={toGridBreakpoint(Banner?.ColumnSize)}
      container
      spacing={mapSpacing(Banner?.ContentSpacing)}
      {...props}
    >
      {ContentComponents}
    </Grid>
  )
}

StrapiContentBanner.propTypes = {
  Banner: PropTypes.object.isRequired,
  Section: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default StrapiContentBanner
