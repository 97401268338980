// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
// helpers
import { mapSpacing, toGridBreakpoint } from '@helpers/strapi'
// context
import { useBannerContext } from '@context/bannerContext'

// components
import BaseBanner from './BaseBanner'
import Content from './BannerContent'
import Link from '../Link'

const useStyles = makeStyles(() => ({
  contentWrapper: ({ BorderColor, BorderWidth, BackgroundColor, Opacity, OverlayColor }) => ({
    position: 'relative',
    zIndex: 1,
    borderStyle: BorderColor && BorderWidth ? 'solid' : 'none',
    borderColor: BorderColor?.ColorHex || null,
    borderWidth: BorderWidth || null,
    backgroundColor: BackgroundColor?.ColorHex || null,
    '&:before':
      Opacity && OverlayColor?.ColorHex
        ? {
            zIndex: '-1',
            content: '""',
            opacity: Opacity || null,
            backgroundColor: OverlayColor?.ColorHex || null,
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }
        : null,
  }),
}))

const SingleSectionBanner = ({ BannerSection = {} }) => {
  const { trackingData } = useBannerContext()

  const { BackgroundColor, BorderColor, BorderWidth, Opacity, ContentSpacing, ColumnSize, OverlayColor } = BannerSection
  const hasImage = BannerSection?.BackgroundImage || BannerSection?.MobileBackgroundImage
  const hasContent = BannerSection?.Content && Array.isArray(BannerSection.Content) && BannerSection.Content.length > 0
  const hasOverlay = BackgroundColor?.ColorHex || null
  const sectionLink = BannerSection?.Link?.Link || null

  const linkProps = sectionLink
    ? {
        component: Link,
        data: sectionLink,
        trackingData,
        underline: 'none',
      }
    : {}

  const classes = useStyles({ BackgroundColor, BorderColor, BorderWidth, Opacity, OverlayColor })

  if (hasContent) {
    const ContentComponents = BannerSection.Content.map(sectionContent => {
      const Banner = sectionContent?.BannerContent || {}
      // Need to remove background from section content because it needs to be on the parent element to the content
      const SectionWithoutBackgroundImage = {
        ...BannerSection,
        ...{ BackgroundImage: undefined, MobileBackgroundImage: undefined },
      }
      return <Content key={`${Banner.id}-section`} Banner={Banner} Section={SectionWithoutBackgroundImage} />
    })
    // content and image
    if (hasImage) {
      return (
        <BaseBanner
          data={{ Banner: BannerSection }}
          className={classes.contentWrapper}
          md={toGridBreakpoint(ColumnSize)}
          spacing={mapSpacing(ContentSpacing)}
        >
          {hasOverlay && <div className={classes.overlay} />}
          {ContentComponents}
        </BaseBanner>
      )
    }
    // content only
    return (
      <Grid
        container
        className={classes.contentWrapper}
        key={BannerSection.id}
        item
        md={toGridBreakpoint(ColumnSize)}
        spacing={mapSpacing(ContentSpacing)}
        {...linkProps}
      >
        {hasOverlay && <div className={classes.overlay} />}
        {ContentComponents}
      </Grid>
    )
  }
  // image only
  if (hasImage) {
    return (
      <BaseBanner
        data={{ Banner: BannerSection }}
        className={classes.contentWrapper}
        md={toGridBreakpoint(ColumnSize)}
        spacing={mapSpacing(ContentSpacing)}
      />
    )
  }

  return null
}

SingleSectionBanner.propTypes = {
  BannerSection: PropTypes.object.isRequired,
}

export default SingleSectionBanner
