// dependencies
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'
// helpers
import { mapSpacing } from '@helpers/strapi'
import { strapi_trackImpression } from '@helpers/google-tag-manager'
// context
import { useStrapiContext } from '@context/strapiContext'
import { BannerContextProvider } from '@context/bannerContext'
// components
import BannerFinanceButtons from '@templates/strapi-cms/content-types/Banner/BannerFinanceButtons'
import { pageTheme } from '@templates/constants'
import BaseBanner from './BaseBanner'
import Sections from './BannerSections'

const MobileBannerWithImageWrapper = styled.div`
  width: 100%;

  @media only screen and (max-width: ${breakPoints.medium}) {
    margin: 0 -15px;
    width: 100vw !important;
    max-width: 100vw !important;
  }
`

const useStyles = makeStyles(theme => ({
  bannerWrapper: ({ DisplaySettings, FullWidth, BannerHeight, BannerMargin, isPLPBanner, isMobile }) => {
    const widthStyles = FullWidth
      ? {
          minWidth: '100vw',
          position: 'relative',
          marginLeft: 'calc(-50vw + 50%)',
          maxWidth: 'unset',
        }
      : {}

    const displays = {
      desktop: {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      mobile: {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    }

    const displayStyles = displays[DisplaySettings || 'both'] || {}
    const isPLP = isPLPBanner
      ? {
          height: '100%',
          width: '100%',
          padding: `0 ${theme.spacing(5)}px`,
        }
      : {}
    return {
      ...widthStyles,
      ...displayStyles,
      ...isPLP,
      minHeight: BannerHeight && !isMobile ? `${BannerHeight}px` : 'auto',
      marginTop: isPLPBanner ? 0 : BannerMargin || 10,
      marginBottom: BannerMargin,
    }
  },
}))

const isHero = (title = '') => {
  const regexp = /hero/gi
  return regexp.test(title)
}

const StrapiBanner = ({ data, contentIndex = null, isPLPBanner }) => {
  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))
  const {
    page: { isPLP, Route },
  } = useStrapiContext()

  const banner = data?.Banner
  const hasImage = isMobile && banner?.MobileBackgroundImage ? banner.MobileBackgroundImage : banner?.BackgroundImage
  const sections = banner?.BannerSections
  const hasSections = sections && Array.isArray(sections) && sections.length > 0
  const hasBackgroundColor = banner?.BackgroundColor?.ColorHex || null

  const context = {
    id: banner?.id,
    Title: banner?.Title,
    isHero: isHero(banner?.Title),
    isPLPBanner: isPLP,
    trackingData: {
      category: 'promotion',
      action: 'promotion click',
      label: banner?.Title,
      value: contentIndex,
      event: 'ee_promoClick',
      ecommerce: {
        promoClick: {
          promotions: [{ contentIndex, banner: banner?.Title, cid: banner?.id, page: Route }],
        },
      },
    },
  }

  const [inViewRef, inView] = useInView({ threshold: 0.2, triggerOnce: true })

  const classes = useStyles({
    DisplaySettings: banner?.DisplaySettings,
    FullWidth: banner?.FullWidth,
    BannerHeight: banner?.BannerHeight,
    BannerMargin: banner?.BannerMargin,
    isPLPBanner,
    isMobile,
  })

  useEffect(() => {
    if (inView) {
      strapi_trackImpression(banner?.id, banner?.Title, isPLP)
    }
  }, [inView, banner, isPLP])

  if ((hasImage && hasSections) || (hasBackgroundColor && hasSections)) {
    return (
      <BannerContextProvider value={context}>
        <MobileBannerWithImageWrapper>
          <BaseBanner
            data={data}
            spacing={mapSpacing(banner?.SectionSpacing)}
            data-testid={banner?.id}
            gridRef={inViewRef}
            className={classes.bannerWrapper}
          >
            <Sections data={sections} />
            {banner.FinanceBanner?.IsFinanceBanner && <BannerFinanceButtons data={banner.FinanceBanner} />}
          </BaseBanner>
        </MobileBannerWithImageWrapper>
      </BannerContextProvider>
    )
  }
  return (
    <BannerContextProvider value={context}>
      {hasImage && (
        <MobileBannerWithImageWrapper>
          <BaseBanner data={data} gridRef={inViewRef} data-testid={banner?.id} className={classes.bannerWrapper} />
        </MobileBannerWithImageWrapper>
      )}
      {hasSections && (
        <Grid
          container
          item
          md={12}
          spacing={mapSpacing(banner?.SectionSpacing)}
          data-testid={banner?.id}
          ref={inViewRef}
          className={classes.bannerWrapper}
        >
          <Sections data={sections} />
        </Grid>
      )}
    </BannerContextProvider>
  )
}

StrapiBanner.propTypes = {
  data: PropTypes.object.isRequired,
  contentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPLPBanner: PropTypes.bool,
}

export default StrapiBanner

export const StrapiPageBannerFragment = graphql`
  fragment StrapiPageBannerFragment on StrapiBanner {
    id
    Title
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    FullWidth
    DisplaySettings
    BannerHeight
    BackgroundColor {
      ColorHex
    }
    BannerMargin
    SectionSpacing
    BackgroundImage {
      ...StrapiImageFragment
    }
    MobileBackgroundImage {
      ...StrapiImageFragment
    }
    BackgroundImageAltText
    FinanceBanner {
      IsFinanceBanner
      ApplyNowBackgroundColor {
        ColorHex
      }
      ApplyNowFontColor {
        ColorHex
      }
      LearnMoreLinkColor {
        ColorHex
      }
    }
    BannerSections {
      BannerSection {
        id
        __typename
        Content {
          BannerContent {
            id
            Alignment
            VerticalAlignment
            ContentSpacing
            ColumnSize
            Orientation
            Title
            Content {
              ... on BannerBannerImage {
                __typename
                BannerImage {
                  id
                  MobileImage {
                    ...StrapiImageFragment
                  }
                  Image {
                    ...StrapiImageFragment
                  }
                  ColumnSize
                }
              }
              ... on BannerBannerText {
                __typename
                BannerText {
                  id
                  __typename
                  PaddingLeft
                  PaddingRight
                  PaddingTop
                  PaddingBottom
                  BannerText {
                    childMarkdownRemark {
                      id
                      html
                    }
                  }
                }
              }
              ... on BannerBannerButton {
                __typename
                BannerButton {
                  ...StrapiBannerButtonFragment
                }
              }
            }
          }
        }
        BackgroundImageSizing
        BackgroundColor {
          ColorHex
        }
        BackgroundImage {
          ...StrapiImageFragment
        }
        BorderColor {
          ColorHex
        }
        BorderWidth
        Link {
          Link {
            ...StrapiLinkFragment
          }
        }
        Opacity
        OverlayColor {
          ColorHex
        }
        Title
        ColumnSize
        ContentSpacing
        MobileBackgroundImage {
          ...StrapiImageFragment
        }
      }
    }
  }
`
