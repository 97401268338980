// dependencies
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import classNames from 'classnames'
// components
import { ButtonLink } from './Link'

const useStyles = makeStyles(theme => ({
  button: ({
    padding,
    backgroundColor,
    borderColor,
    borderRadius,
    flexDirection,
    color,
    backgroundColorHover,
    borderWidth,
    fontSize,
    margin,
  }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection,
    height: 'auto',
    margin,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: backgroundColorHover,
    },
    '&:focus': {
      borderRadius: 0,
      outline: 'none',
    },
    padding,
    borderRadius: borderRadius || 0,
    backgroundColor: backgroundColor || 'transparent',
    textDecoration: 'none',
    borderColor: borderColor || backgroundColor,
    borderWidth,
    borderStyle: 'solid',
    color: color || theme.palette.primary.main,
    fontSize: fontSize || '1.25rem',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
  }),
  buttonIcon: {
    maxHeight: '8rem',
    width: 'auto',
    objectFit: 'cover',
    padding: '4px',
  },
}))

const position = {
  left: 'row',
  top: 'column',
  right: 'row-reverse',
}

const StrapiButton = ({ data, className = '', styles = {}, trackingData = null }) => {
  const buttonStyles = {
    backgroundColor: data?.BackgroundColor?.ColorHex || null,
    borderColor: data?.BorderColor?.ColorHex || null,
    color: data?.TextColor?.ColorHex || null,
    flexDirection: position[data?.IconPosition || 'left'],
    padding: '10px',
    ...styles,
  }
  const classes = useStyles(buttonStyles)
  const linkProps = data?.Link?.Link ? { component: ButtonLink, data: data.Link, trackingData } : {}
  return (
    <ButtonBase data-testid={data?.id} className={classNames(classes.button, className)} {...linkProps}>
      {data?.DisplayIcon && data?.Icon?.url && (
        <img
          className={classes.buttonIcon}
          src={`${data.Icon.url}&fm=webp`}
          alt={data.Icon?.alternativeText || data?.Text || data?.Title}
        />
      )}
      {data?.DisplayText && <span>{data?.Text || data?.Title}</span>}
    </ButtonBase>
  )
}

StrapiButton.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object,
  trackingData: PropTypes.object,
}

export default StrapiButton

export const StrapiButtonFragment = graphql`
  fragment StrapiButtonFragment on StrapiButton {
    __typename
    id
    Title
    Text
    IconPosition
    DisplayIcon
    DisplayText
    DisplayBorder
    TextColor {
      ColorHex
    }
    BorderColor {
      ColorHex
    }
    BackgroundColor {
      ColorHex
    }
    Icon {
      ...StrapiImageFragment
    }
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`
