// dependencies
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Grid as MuiGrid, makeStyles, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import { pageTheme } from '@templates/constants'
// context
import { useBannerContext } from '@context/bannerContext'
// components
import StrapiImage from '../Image'
import Link from '../Link'

const useStyles = makeStyles(() => ({
  baseBanner: ({ backgroundColor, image, children }) => ({
    backgroundColor,
    ...(image?.url && children
      ? {
          backgroundImage: image?.url ? `url(${image.url}&fm=webp)` : null,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {}),
  }),
}))

// ? https://github.com/reactjs/reactjs.org/issues/2120
const Grid = forwardRef(props => <MuiGrid {...props} />)
Grid.displayName = 'MuiGrid'

const StrapiBaseBanner = ({
  children = null,
  data: { Banner },
  className = '',
  spacing = 0,
  gridRef = () => {},
  ...props
}) => {
  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))
  const { trackingData, isHero } = useBannerContext()

  const image = isMobile && Banner?.MobileBackgroundImage ? Banner.MobileBackgroundImage : Banner?.BackgroundImage
  const imageSizing = Banner?.BackgroundImageSizing
  const bannerLink = Banner?.Link?.Link || null

  const styles = {
    backgroundColor: Banner?.BackgroundColor?.ColorHex || null,
    margin: Banner?.BannerMargin ? `${Banner?.BannerMargin}px` : 0,
  }

  const linkProps = bannerLink
    ? {
        component: Link,
        data: bannerLink,
        trackingData,
        underline: 'none',
      }
    : {}

  const imageData = {
    desktop: Banner?.BackgroundImage,
    mobile: Banner?.MobileBackgroundImage,
    title: Banner?.Title,
    imageSizing,
  }

  const classes = useStyles({ ...styles, image, children })

  return children ? (
    <Grid
      className={classNames(classes.baseBanner, className)}
      container
      item
      md={12}
      spacing={spacing}
      ref={gridRef}
      {...linkProps}
      {...props}
    >
      {children}
    </Grid>
  ) : (
    <Grid item xs={12} className={classNames(classes.baseBanner, className)} ref={gridRef} {...props}>
      <StrapiImage data={imageData} link={bannerLink} trackingData={trackingData} loading={isHero ? 'eager' : 'lazy'} />
    </Grid>
  )
}

StrapiBaseBanner.propTypes = {
  data: PropTypes.object.isRequired,
  spacing: PropTypes.number,
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  gridRef: PropTypes.any,
}

export default StrapiBaseBanner
