// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
// components
import SingleSection from './SingleSection'

const StrapiSectionsBanner = ({ data }) => {
  const sections = data || []
  return (
    <>
      {sections.map(({ BannerSection }) =>
        BannerSection ? <SingleSection BannerSection={BannerSection} key={BannerSection.id} /> : null,
      )}
    </>
  )
}

StrapiSectionsBanner.propTypes = {
  data: PropTypes.array.isRequired,
}

export default StrapiSectionsBanner
