import { useRef, useState, useCallback, useEffect } from 'react'

/**
 * Validates if a given image is already loaded by the browser
 * @returns {{ imageRef: ReactRef, isLoaded: boolean, onLoad: () => void }}
 */
const useImageLoaded = () => {
  const imageRef = useRef()
  const [isLoaded, setLoaded] = useState(false)

  const onLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (imageRef?.current?.complete) {
      onLoad()
    }
  }, [onLoad])

  return { imageRef, isLoaded, onLoad }
}

export default useImageLoaded
